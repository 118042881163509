import { useSearchParams } from 'react-router-dom';
import { Container, ContentLabel, SectionLabel } from './styles';

const DataDeletionRequest = () => {
    const [searchParams] = useSearchParams();
    const source = searchParams.get('source');

    const isFromApp = source === 'webview';

    return (
        <Container isFromApp={isFromApp}>
            <SectionLabel>Data Deletion Request</SectionLabel>
            <ContentLabel>
                At Tink, we respect your right to control your personal data. If you wish to delete your user data from our systems, please follow the steps below:
            </ContentLabel>

            <SectionLabel>How to Request Data Deletion:?</SectionLabel>
            <ContentLabel>
                <b>1. Send an Email: </b>Send an email to moneytink9@gmail.com with the subject line "Data Deletion Request."
                <br />
                <br />
                <b>2. Include Your Details: </b>In the email, please include the following details:
                <br />
                <br />
                - Your full name
                <br />
                - The email address associated with your Tink account
                <br />
                - A clear and explicit request to delete your user data
                <br />
                <br />
                <b>1. Verification: </b>To ensure the request is legitimate, we may require you to verify your identity. We will provide you with instructions on how to complete this step.
            </ContentLabel>

            <SectionLabel>Data Deletion Process:</SectionLabel>
            <ContentLabel>
                - Once your request and identity verification are received, we will proceed with the deletion process.
                <br />
                - Your user data will be permanently removed from our systems.
                <br />
                - We will send a confirmation email once your data has been deleted.
            </ContentLabel>

            <SectionLabel>Important Notes:</SectionLabel>
            <ContentLabel>
                - Deleting your data means you will lose all access to your Tink account and any associated financial information.
                <br />
                - Be aware that some data may be retained for legal or administrative purposes, but it will not be accessible or used for any other purpose.
                <br />
                <br />
                We are committed to processing your data deletion request promptly while adhering to data privacy and security standards.
                <br />
                <br />
                If you have any questions or need further assistance, please contact us at moneytink9@gmail.com.
            </ContentLabel>
            <SectionLabel>Effective Date</SectionLabel>
            <ContentLabel>
                This data deletion request process was last updated on 2023/10/19.

                <br /><br />
                Thank you for using Tink, and we respect your decision to manage your data as you see fit.
            </ContentLabel>
        </Container>
    );
}

export default DataDeletionRequest;

import { useSearchParams } from 'react-router-dom';
import { Container, ContentLabel, SectionLabel } from './styles';

const Privacy = () => {
    const [searchParams] = useSearchParams();
    const source = searchParams.get('source');

    const isFromApp = source === 'webview';

    return (
        <Container isFromApp={isFromApp}>
            <SectionLabel>Privacy Policy - Steelia AI</SectionLabel>
            <ContentLabel>
                Welcome to Steelia AI - the Chat Bot application integrated with GPT API. We highly value your trust and concern for protecting personal information while using our application. Below is our Privacy Policy that we commit to adhere to and safeguard your personal information.
            </ContentLabel>

            <SectionLabel>Information We Collect</SectionLabel>
            <ContentLabel>
                <b>1. Device Information: </b>We may collect information about the device you use to access Steelia, including device type, operating system, and unique device identifiers.
                <br />
                <br />
                <b>2. Images Provided By You: </b>We use images actively provided by you to serve AI image recognition in the application.
                <br />
                <br />
                <b>3. Face data: </b>We use face data from the images you upload for the face swap feature in this app. Additionally, we do not automatically collect any other data related to faces.
            </ContentLabel>

            <SectionLabel>How We Use Your Information?</SectionLabel>
            <ContentLabel>
                <b>1. Chat Bot and GPT API Feature: </b>Information is input into the system to provide answers and our services.
                <br />
                <br />
                <b>2. Fortune Telling, Image Creation, Book Summarization, Video Summarization, Plant, and Location Recognition: </b>Information is used to generate accurate results and provide a diverse user experience.
                <br />
                <br />
                <b>3. Face swap: </b>The face data uploaded by you will only be used for the Face swap feature and will not be used for any other purpose. We are committed to keeping your information safe and secure.
                <br />
                <br />
                Images uploaded by you will be stored for a maximum of 30 days on our system for your convenience to retrieve and download. After 30 days, the history of these face swaps will be automatically deleted. In addition, you can also proactively delete these records from within the Steelia AI application. When a record is deleted, the images related to that record are also deleted (including 1 source image, 1 target image and 1 result image).
            </ContentLabel>

            <SectionLabel>Data Security</SectionLabel>
            <ContentLabel>
                <b>1. Data Safety: </b>We commit to protecting your personal information and using advanced security measures to prevent unauthorized access.
            </ContentLabel>

            <SectionLabel>Information Sharing</SectionLabel>
            <ContentLabel>
                <b>1. No Information Sharing: </b>We commit not to share your personal information with any third party without your consent.
            </ContentLabel>

            <SectionLabel>Your Choices</SectionLabel>
            <ContentLabel>
                <b>1. Free and Paid Options: </b>You can use the app for free for 3 days. Afterward, to continue usage, you can choose from various paid plans according to their needs.
            </ContentLabel>

            <SectionLabel>Policy Changes</SectionLabel>
            <ContentLabel>
                <b>1. Change Notification: </b>We may update this Privacy Policy. Any changes will be clearly communicated on the website or through the app for you to stay informed.
            </ContentLabel>

            <SectionLabel>Contact Us</SectionLabel>
            <ContentLabel>
                If you have any questions or feedback regarding our Privacy Policy, please contact us at aisteelia369@gmail.com.
                <br /> <br />
                Thank you sincerely for your trust and using our application!
                <br /> <br />
                The Privacy Policy is effective from December 17, 2023.
            </ContentLabel>
        </Container>
    );
}

export default Privacy;
